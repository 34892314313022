app-offers {
  .coupon {
    width: 45%;
    background: white;
    border: 1px solid #777;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 6px;
    display: inline-block;
    overflow: hidden;

    &.disabled {
      opacity: 0.3;
    }

    .discount--holder div {
      min-height: 100px;
      color: white;
      border-right: 1px solid #777;
      text-shadow: 2px 2px 3px rgba(150, 150, 150, 0.5);
    }

    .platinum-tier div {
      background: #006e2e; /* Old browsers */
      background: -moz-linear-gradient(-45deg, #006e2e 0%, #009344 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, #006e2e 0%,#009344 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #006e2e 0%,#009344 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006e2e', endColorstr='#009344',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }

    .gold-tier div {
      background: #14bc63; /* Old browsers */
      background: -moz-linear-gradient(-45deg, #14bc63 0%, #47d187 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, #14bc63 0%,#47d187 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #14bc63 0%,#47d187 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#14bc63', endColorstr='#47d187',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }

    .silver-tier div {
      background: #6ad89d; /* Old browsers */
      background: -moz-linear-gradient(-45deg, #6ad89d 0%, #aaefca 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, #6ad89d 0%,#aaefca 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #6ad89d 0%,#aaefca 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ad89d', endColorstr='#aaefca',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }

    .bronze-tier div {
      background: #caf7df; /* Old browsers */
      background: -moz-linear-gradient(-45deg, #caf7df 0%, #edfff5 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, #caf7df 0%,#edfff5 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #caf7df 0%,#edfff5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#caf7df', endColorstr='#edfff5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }

    h2 {
      span {
        font-size: 50px;
        display: block;
        line-height: 40px;
        padding-top: 15px;
      }
    }

    h2, p {
      margin: 0;
    }

    h3 {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}
