/*------------------------------------*\
  #ALERTS
\*------------------------------------*/
.alert {
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 5px;
  vertical-align: middle;

  &:before {
    min-width: 1.2em;
    display: inline-block;
    font-size: 1.4em;
    vertical-align: middle;
  }

  .buttons {
    margin-top: 0.75em;
  }

  &--error,
  &--danger {
    a {
      background: #f24646;
      color: #fff;
    }
    background: #f24646;
    color: #fff;

    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }

  &--success {
    background: #74b14b;
    color: #fff;

    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }

  &--info {
    background: #4b76b1;
    color: #fff;

    &:before {
      content: " ";
      font-family: FontAwesome;
    }
  }

  &--warning {
    background: #f2cc46;
    color: #302e26;

    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }
}
