@media print, screen{

    @page {size:auto; margin: 0mm;}
    .survey-chart {
        margin:10px 0px; 
        padding-top:0px; 
        border: 1px solid #0059b3; 
        min-width:999px; 
        overflow-x: scroll; 
        background-color:#fff;
        h2 {
            font-size:44px; 
            margin-top:0px; 
            background-color: #0059b3 !important; 
            -webkit-print-color-adjust: exact; 
            color:#fff !important; 
            padding:25px; 
            text-align: center;
            }
        h3 {
            font-size:150%;
            font-weight: 600;
            padding:1px 0 10px 0; 
            color:#0059b3 !important; 
            margin-top:0; 
            margin-bottom:3px;
            }
        .big-percentage {
            font-size:48px; 
            font-weight: 600; 
            color:#0059b3 !important;
            }
        p.title {
            margin:0; 
            display:block; 
            font-size:48px; 
            font-weight: 600; 
            color:#0059b3 !important; 
            text-align: center;
            }
        p.title strong,  p.title span {
            color:#0059b3 !important;
            }
        table {
            margin:0 auto;
            }
        .block-left, .block-right {
            margin:0 0 0 5px; 
            padding:5px; 
            width: 47%; 
            display:inline-block; 
            vertical-align: text-top;
            }
        .block-left, .block-right, .bottom-box {
            font-size:120%;
            }
        .block-left p, .block-right p {
            margin:5px 0 0 0;
            }
        .middle-box {
            margin-bottom:15px;
            }
        }

    .bottom-box {
        margin:0 auto; 
        padding:0 10px; 
        text-align: center;
        p {
            font-size: 12px;
            }
        table tr th {
            background-color: #0059b3 !important; 
            -webkit-print-color-adjust: exact; 
            print-color-adjust: exact; 
            color:#fff !important;
            }
        table tr th, table tr td {
            white-space: nowrap; 
            padding:7px; 
            width:100px;
            }
        table, table tr th, table tr td {
            border:1px solid #A0A0A0;
            }
        .table-responsive {
            max-width:90%; 
            margin:0 auto;
            }
        table {
            border-collapse: collapse;
            }
        }

    .middle-box {max-width:90%; margin:0 auto;}
    .answer-box {display:block;}
}
