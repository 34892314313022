@import '//bootstrap/scss/bootstrap';
$brand-1: #550385;

$theme-colors: (
  "primary": #550385,
);

.text-primary {
  color: $brand-1 !important;
}

.brand-1 {
  color: $brand-1;
}

body {
  background: #f6f6f6;
  font-family: Lato,'Lato',sans-serif;
}

a{cursor:pointer;}

.site_head {
  .navbar {
    background: #fff;
  }

  .navbar-brand {
    width: 12rem;
    margin-right: 1.5em;
    padding: 0.5em 0;
    display: inline-block;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.central_panel {
  width: 90%;
  margin: 0 auto;
  max-width: 500px;
  margin-top: 5%;
}

.well--white {
  background: #fff;
}
.well--black{ background:#111; color:#fff;
a{color:#fff;}}


/* BUTTON OVERRIDES */

.btn-primary {
  background-color: $brand-1;
  border-color: $brand-1;

  &:hover,
  &[disabled]:hover,
  &:focus {
    background-color: darken($brand-1, 10);
  }
}

.login {
  .site_logo {
    width: 15em;
    max-width: 50%;
    display: block;
    margin: 0 auto;
    margin-bottom: 2em;
  }

  .well {
    padding: 10%;
  }
}

h1,
.h1 {
  font-weight: 300;
}

a {
  color: $brand-1;
}

thead{ background:#fff;}

.order_title{
  .btn{margin-top:2em;}
}

.prescription{ .well{min-height:18em;}}

.date_updated {
  font-style: italic;
  margin-bottom: 20px;
  display: block;
}

.submitted{
  .ng-invalid.btn, .ng-invalid.form-control, textarea.ng-invalid, .ng-invalid.validation:before{ background:#f9d1d1; border-color:#940000; color:#940000; position:relative; outline:none; }

.ng-invalid.btn{ &:after{content:"Required"; padding:2px 5px; display:block; position:absolute;  bottom:100%; border-radius:3px; font-size:0.75em; right:0; background-color:#940000; color:#fff;}}
}

.header-block {top:0; display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end;}
.header-block:after {width: 100%; margin-top: 0.5em; border-bottom:1px solid $brand-1;}
.site-logo{ top: 1in; left:1in; position: fixed; width:8em; display: inline; }
.site-address{ top: 0; color:$brand-1; text-align:right; font-size:0.9em; display: inline;}
.print_only{display:none;}
.site_only {display: block;}
.btn-print{ margin-top:1em;}
.signature{ display:block; clear:both;}
.watermark_image{display:none;}


@media print{
  body { zoom: 70%; margin: 1in; }
  .print_only{display:block;}
  .btn-print{display:none;}
  .site_only {display: none;}
  .btn{display:none;}
  .watermark{
    .watermark_image{ position:absolute; top:200px; left:0; right:0; height:auto; display:block; }
  }

}

/*------------------------------------*\
  #ALERTS
\*------------------------------------*/
.alert {
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 5px;
  vertical-align: middle;

  &:before {
    min-width: 1.2em;
    display: inline-block;
    font-size: 1.4em;
    vertical-align: middle;
  }

  .buttons {
    margin-top: 0.75em;
  }

  &--error,
  &--danger {
    a {
      background: #f24646;
      color: #fff;
    }
    background: #f24646;
    color: #fff;

    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }

  &--success {
    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }

  &--info {
    background: #4b76b1;
    color: #fff;

    &:before {
      content: " ";
      font-family: FontAwesome;
    }
  }

  &--warning {
    background: #f2cc46;
    color: #302e26;

    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }
}

.alert-success{ color: $brand-1; background-color: #f4e2ff; border-color: #dcc2eb; }

.red-text {
  color:red;
}
.pl-15 {
  padding-left: 15px;
}
.half-width {
  max-width: 50%;
}

.mt-05 {
  margin-top: 0.5em;
}
.mt-0 {
  margin-top: 0 !important;
}

.table-warning {
  background-color: lightgoldenrodyellow !important;
}

.middle-aligned {
  vertical-align: middle !important;
}

.light-green {
  color: #25a71e !important;
}

.disabled {
  color: grey !important;
  pointer-events: none;
  cursor: not-allowed;
}

.mr-0 {
  margin-right: 0;
}
.mr-1-7 {
  margin-right:1.7em;
}
.ml-0 {
  margin-left: 0;
}
.mt-0 {
  margin-top: 0;
}
.pl-15 {
  padding-left: 15px;
}
.ml-1em {
  margin-left:1em;
} 
.mb-0-5 {
  margin-bottom:0.5em;
}
.mb-minus-2em {
  margin-bottom: -2em;
}
.pagination-active-background {
  background-color: #55038529 !important;
}
.prescription .well-small {
  min-height: 4em;
}