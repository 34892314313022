@media print{
    @page {size:auto; margin: 0mm;}
    .summary {
        margin:15px 0px; 
        padding-top:0px; 
        background-color:#fff;
    }
    .customer-inputs{
        border: 1px solid #e6e6e6;
        min-height: 25px;
    }
}
@media only screen {
    .customer-inputs{
        border: 1px solid #e6e6e6;
        max-height: 150px;
        min-height: 25px;
        overflow-y:scroll;
    }
}
.summary_head{
    text-align: center;
    font-weight: 600;
}
.customer-inputs p {
    padding:3px 8px;
}
.questionInfo{
    margin-bottom:30px;
}
.questionInfo > div{
    display:block;
    padding:3px;
}
.answerGTotal, .answerGTotallabel {
    font-weight: 600;
    margin-left: 15px;
    display:inline-block;
}
.answerTotal {
    margin-left:5px;
    display:inline-block;
}
.answerTotal:before {
    content:"Total: ";
}
.answerPercentage {
    margin-left:5px;
    display:inline-block;
    color:#778899;
}
.answerPercentage:before {
    content:"(";
}
.answerPercentage:after {
    content:")";
}
@media all and (max-width: 500px) {
    .answerDesc {
        margin-left:5px;
        display:block;
        width: 100%;
    }
    .questionInfo span{
        display:block;
        margin:8px 0px 8px 0px;
    }
}
@media all and (min-width: 500px) {
    .answerDesc {
        margin-left:10px;
        display:inline-block;
        width: 220px;
    }
    .questionInfo span{
        display:block;
        margin:8px 0px 8px 170px;
    }
}
.selection-input{
    font-size:100%;
    margin-left:5px;
}
.selection-input-subgroup{
    margin-left:15px;
}
.greatest-score{
    color:blue;
    font-weight: 600;
}
.individual-set-checked{
    font-weight: 600;
    font-size: 16px;    
}
.individual-set-checked:before{
    content: "[x] ";
}
.individual-set-unchecked{
    margin-left:22px;
}
.answer-box{
    margin-left:38px;
}