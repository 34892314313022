@import "partials/general",
"partials/questionnaire-summary",
"partials/questionnaire-chart",
"partials/alerts",
"partials/coupons";

// regular style toast
@import 'ngx-toastr/toastr';
::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius: 3px;
    margin: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #bfbfbf;
  }